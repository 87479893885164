import {Injectable} from '@angular/core';
import {DebugService} from "@app/core-module/services/debug.service";
import {Profile} from "@shared/models/profile";
import {BehaviorSubject, of} from 'rxjs';
import {Observable} from 'rxjs';
import {catchError, combineLatest, distinctUntilChanged, filter, map, merge, shareReplay, switchMap} from 'rxjs/operators';
import {ApiService} from '../services/api.service';
import {RxUtilsService} from '../services/rx-utils.service';
import {TokenState} from './token.state';
import {Router} from '@angular/router';
import {AlertsService} from '@app/core-module/services/alerts.service';

@Injectable()
export class CurrentProfileState {

  private currentProfile: Observable<Profile>;
  private reading: Observable<boolean>;

  private reload$ = new BehaviorSubject(true);

  constructor(private tokenState: TokenState,
              private apiService: ApiService,
              private rxUtilsService: RxUtilsService,
              private debugService: DebugService,
              private router: Router,
              protected alertsService: AlertsService) {


    const initiator = this.tokenState.getToken().pipe(
      filter(it => it !== null),
      combineLatest(this.reload$, (token, reload) => token),
    );

    const nonNullCurrentProfile = initiator.pipe(
      switchMap(() => this.apiService.getCurrentProfile().pipe(
        catchError(error => {
          if (!this.router.url.includes('/auth/login')) {
            this.alertsService.addAlert({type: 'danger', message: 'Error while reading current user!'});
          }
          return of(null);
        }),
      )),
      shareReplay(1)
    );
    // when token is null, set currentProfile to null
    this.currentProfile = nonNullCurrentProfile.pipe(
      merge(this.tokenState.getToken().pipe(
        filter(it => it === null),
        map(it => null))),
      shareReplay(1) // must shareReplay, as nonNullCurrentProfile with replay it's last value
    );

    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.currentProfile);
  }

  getCurrentProfile(): Observable<Profile | null> {
    return this.currentProfile;
  }

  getCurrentProfileId(): Observable<number | null> {
    return this.getCurrentProfile().pipe(
      map(user => user ? user.id : null),
      distinctUntilChanged()
    );
  }

  /**
   * Returns Observable emitting only true of false, never null or an error.
   */
  getReadingCurrentProfile(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  reload() {
    this.reload$.next(true);
  }
}
