import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from "@shared/models/profile";
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {defaultDebounceTime, getLogoPath} from '@app/shared-module/utils/utils';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {debounceTime, filter, first} from 'rxjs/operators';
import {LanguageService} from '@app/core-module/services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [routerTransition()]
})
export class LoginComponent implements OnInit {

  currentProfile: Observable<Profile>;
  readingCurrentProfile: Observable<boolean>;

  showInvalidCredentialsMessage = false;
  showErrorOnLoggingMessage = false;
  showInvalidTfaCode = false;
  logging = false;

  tfaFlag = false;

  formGroup: FormGroup;
  showRecaptcha = false;
  logoPath: string;

  constructor(private currentProfileState: CurrentProfileState,
              private authenticationService: AuthenticationService,
              private router: Router,
              private languageService: LanguageService) {
    this.logoPath = getLogoPath();
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    this.readingCurrentProfile = this.currentProfileState.getReadingCurrentProfile().pipe(debounceTime(defaultDebounceTime));
  }

  ngOnInit() {
    this.currentProfile.pipe(
      filter(it => it !== null),
      first()
    ).subscribe(profile => {
      if (profile.id) {
        if (profile.user != null && profile.user.isAdminFI) {
          this.router.navigate(['/admin']).then(() => this.logging = false);
        } else if (profile.user != null) {
          this.router.navigate(['/fi']).then(() => this.logging = false);
        } else {
          this.router.navigate(['/profile']).then(() => this.logging = false);
        }
      }
    });

    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      tfaCode: new FormControl('')
    });
    if (environment.recaptcha.enabled) {
      this.showRecaptcha = true;
      this.formGroup.addControl('recaptcha', new FormControl(null, [Validators.required]));
    }
  }

  login() {
    this.logging = true;
    this.showInvalidCredentialsMessage = false;
    this.showErrorOnLoggingMessage = false;
    this.showInvalidTfaCode = false;

    const email = this.formGroup.get('email').value;
    const password = this.formGroup.get('password').value;
    const tfaCode = this.formGroup.get('tfaCode').value;

    this.authenticationService.login(email, password, tfaCode, this.showRecaptcha ? this.formGroup.get('recaptcha').value : null)
      .subscribe((res: any) => {
          if (this.showRecaptcha) {
            this.formGroup.get('recaptcha').reset();
            this.formGroup.get('recaptcha').markAsUntouched();
            this.formGroup.get('recaptcha').markAsPristine();
          }
          if (res.status === 206) {
            this.tfaFlag = true;
            this.logging = false;
          }
        },
        (error: HttpErrorResponse) => {
          if (this.showRecaptcha) {
            this.formGroup.get('recaptcha').reset();
            this.formGroup.get('recaptcha').markAsUntouched();
            this.formGroup.get('recaptcha').markAsPristine();
          }
          if (error.status === 401) {
            this.showInvalidCredentialsMessage = true;
          } else if (error.status === 400) { // TODO maybe also check the message, not just status
            this.showInvalidTfaCode = true;
          } else {
            this.showErrorOnLoggingMessage = true;
          }
          this.logging = false;
        });
  }

  setLanguage(languageCode: string) {
    this.languageService.setLanguage(languageCode);
  }

}
