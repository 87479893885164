import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractApiService} from '@app/core-module/services/abstract.api.service';
import {Account} from '@shared/models/account';
import {Balance} from '@shared/models/balance';
import {CreditScheduleConfigurationEntry} from '@shared/models/credit-schedule-configuration-entry';
import {FinancialInstitution} from '@shared/models/financial-institution';
import {Deposit} from '@shared/models/non-db/deposit';
import {Fee} from '@shared/models/non-db/fee';
import {TransferBetweenAccounts} from '@shared/models/non-db/transfer-between-accounts';
import {Withdrawal} from '@shared/models/non-db/withdrawal';
import {Ofac, OfacProfileResult, OfacRDCResult} from '@shared/models/ofac';
import {RDC} from '@shared/models/rdc';
import {RdcActivity} from '@shared/models/rdc-activity';
import {RdcsAmountPerDay} from '@shared/models/rdcs-amount-per-day';
import {RdcsPerDay} from '@shared/models/rdcs-per-day';
import {ReconcileReport} from '@shared/models/reconcile-report';
import {ReturnCode} from '@shared/models/return-code';
import {SelfRegisteredUser} from '@shared/models/self-registered-user';
import {Settlement} from '@shared/models/settlement';
import {Transaction} from '@shared/models/transaction';
import {User} from '@shared/models/user';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TransactionFilter} from '@shared/models/non-db/transaction-filter';
import {RdcFilter} from '@shared/models/non-db/rdc-filter';
import {AdminKycProfilesFilter} from '@shared/models/non-db/admin-kyc-profiles-filter';
import {AssignedTemplate, Template} from '@shared/models/template';
import {AnsweredQuestion, QuestionDefinition} from '@shared/models/question-definition';
import {Profile} from '@shared/models/profile';
import {OfacCheckForName} from '@shared/models/ofac-check-for-name';
import {OfacLogsByNameFilter} from '@shared/models/non-db/ofac-logs-by-name-filter';
import {OfacLogByNameEntry} from '@shared/models/non-db/ofac-log-by-name-entry';
import {OfacLogsByAddressFilter} from '@shared/models/non-db/ofac-logs-by-address-filter';
import {OfacLogByAddressEntry} from '@shared/models/non-db/ofac-log-by-address-entry';
import {OfacCheckForAddress} from '@shared/models/ofac-check-for-address';
import {WireTransferForm} from '@shared/models/wire-transfer-form';
import {CustomerDocument} from '@shared/models/customer-document';
import {Person} from '@shared/models/person';
import currencies from '@shared/assets/data/currencies/currencies';
import {WireTransferBank} from '@shared/models/wire-transfer-bank';
import {WireTransferFormActivity} from '@shared/models/wire-transfer-form-activity';
import {WireFormFilter} from '@shared/models/non-db/wire-form-filter';
import {NonWorkingDay} from '@shared/models/non-working-day';
import {DefaultQuestionnaire} from '@shared/models/default-questionnaire';
import {TranSubType} from '@shared/models/tran-sub-type';
import {ReturnFee} from '@shared/models/non-db/return-fee';
import {WiresBatches} from '@shared/models/wires_batches';
import {WireTransferFormStatusTypeConverted} from '@shared/models/wire-transfer-form-status-type';
import {KycProfile} from '@shared/models/kyc-profile';
import {getPersonName, IncludeKycProfile} from '@shared/utils/utils';
import {AdminBankProfilesFilter} from '@shared/models/non-db/admin-bank-profiles-filter';
import {QuestionFormData} from '@app/shared-module/utils/question.utils';
import {UpstreamBank} from '@shared/models/upstream-bank';
import {BackendConfiguration} from '@shared/models/non-db/configuration';
import {BraidAccountBalance} from '@shared/models/braid/account';

@Injectable()
export class AdminApiService extends AbstractApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getBanks(): Observable<FinancialInstitution[]> {
    return this.createGetRequest<FinancialInstitution[]>('/admin/banks');
  }

  public addBank(fi: FinancialInstitution): Observable<FinancialInstitution> {
    return this.createPostRequest<FinancialInstitution>('/admin/banks', fi);
  }

  public updateBank(fi: FinancialInstitution): Observable<FinancialInstitution> {
    return this.createPutRequest<FinancialInstitution>('/admin/banks/' + fi.id, fi);
  }

  public updateBankTfa({bankId, tfaMandatory}): Observable<FinancialInstitution> {
    return this.createPutRequest<FinancialInstitution>(`/admin/banks/${bankId}/tfa-configuration`, {tfaMandatory});
  }

  public updateBankCustomerConfiguration({bankId, onlyConfirmedCustomers}): Observable<FinancialInstitution> {
    return this.createPutRequest<FinancialInstitution>(`/admin/banks/${bankId}/customers-configuration`, {onlyConfirmedCustomers});
  }

  public updateBankCanCreateSegregateAccount({bankId, canCreateSegregateAccount}): Observable<FinancialInstitution> {
    return this.createPutRequest<FinancialInstitution>(`/admin/banks/${bankId}/segregated-account-configuration`,
      {canCreateSegregateAccount});
  }

  public updateBankMonthlyFeeConfiguration(fi: FinancialInstitution, minimalMonthlyFee: number): Observable<FinancialInstitution> {
    return this.createPutRequest<FinancialInstitution>(`/admin/banks/${fi.id}/monthly-fee-configuration`,
      {minimal_monthly_fee: minimalMonthlyFee});
  }

  public deleteBank(fi: FinancialInstitution): Observable<FinancialInstitution> {
    return this.createDeleteRequest<FinancialInstitution>('/admin/banks/' + fi.id);
  }

  public getAdminUsers(): Observable<User[]> {
    return this.createGetRequest<User[]>('/admin/users');
  }

  public addAdminUser(user: User): Observable<User> {
    return this.createPostRequest<User>('/admin/users', user);
  }

  public updateAdminUser(user: User): Observable<User> {
    return this.createPutRequest<User>('/admin/users/' + user.id, user);
  }

  public updateApprovalLimitForUser(user: User, approvalLimit): Observable<User> {
    return this.createPutRequest<User>('/admin/users/' + user.id + '/approval-limit', {approvalLimit: approvalLimit});
  }

  public deleteAdminUser(user: User): Observable<User> {
    return this.createDeleteRequest<User>('/admin/users/' + user.id);
  }

  public getAdminUser(id: string): Observable<User> {
    return this.createGetRequest<User>('/admin/users/' + id);
  }

  public getBank(id: string): Observable<FinancialInstitution> {
    return this.createGetRequest<FinancialInstitution>('/admin/banks/' + id);
  }

  public getUsers(bank: FinancialInstitution): Observable<User[]> {
    return this.createGetRequest<User[]>('/admin/banks/' + bank.id + '/users');
  }

  public addUser(user: User, bank: FinancialInstitution): Observable<User> {
    return this.createPostRequest<User>('/admin/banks/' + bank.id + '/users', user);
  }

  public addAccount(account: Account, options: {
    currentBank: FinancialInstitution,
    kycBankId?: number
  }) {
    return this.createPostRequest<{
      acc: Account,
      isError: boolean,
      message: string
    }>(
      '/admin/banks/' + (options.kycBankId ? options.kycBankId : options.currentBank.id) + '/accounts', account);
  }

  public updateAccount(account: Account, options: {
    currentBank: FinancialInstitution,
    kycBankId?: number
  }) {
    return this.createPutRequest<{
      acc: Account,
      isError: boolean,
      message: string
    }>(
      '/admin/banks/' + (options.kycBankId ? options.kycBankId : options.currentBank.id) + '/accounts/' + account.id, account);
  }

  public setDefaultAccount(account: Account, bank: FinancialInstitution): Observable<Account> {
    return this.createPostRequest<Account>('/admin/banks/' + bank.id + '/accounts-set-default/' + account.id, account);
  }

  public unsetDefaultAccount(account: Account, bank: FinancialInstitution): Observable<Account> {
    return this.createPostRequest<Account>('/admin/banks/' + bank.id + '/accounts-unset-default/' + account.id, account);
  }

  public setFeeAccount(account: Account, bank: FinancialInstitution): Observable<Account> {
    return this.createPostRequest<Account>('/admin/banks/' + bank.id + '/accounts-set-fee/' + account.id, account);
  }

  public deleteAccount(account: Account, bank: FinancialInstitution): Observable<Account> {
    return this.createDeleteRequest<Account>('/admin/banks/' + bank.id + '/accounts/' + account.id);
  }

  public getAllAccounts(): Observable<Account[]> {
    return this.createGetRequest<Account[]>('/admin/accounts');
  }

  public getAllTransactions(): Observable<Transaction[]> {
    return this.createGetRequest<Transaction[]>('/admin/transactions');
  }

  public getWireTransferTransactions(wireTransferId: number): Observable<Transaction[]> {
    return this.createGetRequest<Transaction[]>('/admin/transactions/wire-transfer/' + wireTransferId);
  }

  public createDeposit(deposit: Deposit): Observable<Deposit> {
    return this.createPostRequest<Deposit>('/admin/transactions/deposit/', deposit);
  }

  public createWithdrawal(withdrawal: Withdrawal): Observable<Withdrawal> {
    return this.createPostRequest<Withdrawal>('/admin/transactions/withdrawal/', withdrawal);
  }

  public createFee(fee: Fee): Observable<Fee> {
    return this.createPostRequest<Fee>('/admin/transactions/fee/', fee);
  }

  public createReturnFee(transaction: Transaction, returnFee: ReturnFee): Observable<Fee> {
    return this.createPostRequest<Fee>(`/admin/transactions/${transaction.id}/return-fee`, returnFee);
  }

  public getFeeSubTypes(): Observable<TranSubType[]> {
    return this.createGetRequest<TranSubType[]>('/admin/transactions/fee/sub-types');
  }

  public createTransferBetweenAccounts(transferBetweenAccounts: TransferBetweenAccounts): Observable<TransferBetweenAccounts> {
    return this.createPostRequest<TransferBetweenAccounts>('/admin/transactions/transfer-between-accounts/', transferBetweenAccounts);
  }

  public getAllBalances(includeKycProfile: IncludeKycProfile): Observable<Balance[]> {
    return this.createGetRequest<Balance[]>('/admin/balances?includeKycProfile=' + includeKycProfile);
  }

  public getRDCsPerDay(): Observable<RdcsPerDay[]> {
    return this.createGetRequest<RdcsPerDay[]>('/admin/rdcs/created-per-day');
  }

  public getRDCsAmountPerDay(): Observable<RdcsAmountPerDay[]> {
    return this.createGetRequest<RdcsAmountPerDay[]>('/admin/rdcs/created-amount-per-day');
  }

  public getAllBalancesPdf(balances: Balance[]): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/admin/balances/pdf?${balances.map(it => `id=${it.id}`).join('&')}`);
  }

  public getMonthlyStatementPdf(accountId: number, year: number, month: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/statements/monthly?accountId=' + accountId + '&year=' + year + '&month=' + month);
  }

  public getYearlyStatementPdf(accountId: number, year: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/statements/yearly?accountId=' + accountId + '&year=' + year);
  }

  public getAccounts(bank: FinancialInstitution): Observable<Account[]> {
    return this.createGetRequest<Account[]>('/admin/banks/' + bank.id + '/accounts');
  }

  public getAccount(id: number): Observable<Account> {
    return this.createGetRequest<Account>('/admin/accounts/' + id);
  }

  public getBraidAccountBalance(id: number): Observable<BraidAccountBalance> {
    return this.createGetRequest<BraidAccountBalance>(`/admin/accounts/${id}/braid-balance`);
  }

  public getAccountByKycProfileId(id: number): Observable<Account> {
    return this.createGetRequest<Account>('/admin/accounts/by-kyc-profile-id/' + id);
  }

  public getTransactions(account: Account, offset: number = 0, order: string = 'desc'): Observable<{
    count: number,
    transactions: Transaction[]
  }> {
    return this.createGetRequest<{
      count: number,
      transactions: Transaction[]
    }>('/admin/accounts/' + account.id + '/transactions?offset=' + offset + '&order=' + order);
  }

  public getAllAccountTransaction(account: Account): Observable<Transaction[]> {
    return this.createGetRequest<Transaction[]>('/admin/accounts/' + account.id + '/all-transactions');
  }

  public getTransactionsPdf(account: Account): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/accounts/' + account.id + '/transactions-pdf');
  }

  public getRdcActivities(rdc: RDC): Observable<RdcActivity[]> {
    return this.createGetRequest<RdcActivity[]>('/admin/rdcs/' + rdc.id + '/activities');
  }

  public getRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs');
  }

  public getRDC(id: number): Observable<RDC> {
    return this.createGetRequest<RDC>('/admin/rdcs/' + id);
  }

  public updateRDC(rdc: RDC): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id, rdc);
  }

  public getPendingRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/pending');
  }

  public getConfirmedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/confirmed');
  }

  public getSettlementPendingRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/settlement-pending');
  }

  public getSettlementRejectedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/settlement-rejected');
  }

  public getApprovedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/approved');
  }

  public getDataReviewRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/data-review');
  }

  public getSettlementReadyRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/settlement-ready');
  }

  public getSettlementReadyRDCsCount(): Observable<number> {
    return this.createGetRequest<number>('/admin/rdcs/status/settlement-ready/count');
  }

  public getNotProcessedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/not-processed');
  }

  public getScanningQueueRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/scanning-queue-rdcs');
  }

  public getScanningQueueRDCsCount(): Observable<number> {
    return this.createGetRequest<number>('/admin/rdcs/status/scanning-queue-rdcs/count');
  }

  public getExcelPopulatedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/excel-populated');
  }

  public getRejectedRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/rejected');
  }

  public getMoreInfoRequiredRDCs(): Observable<RDC[]> {
    return this.createGetRequest<RDC[]>('/admin/rdcs/status/more-info-required');
  }

  public getSettlements(): Observable<Settlement[]> {
    return this.createGetRequest<Settlement[]>('/admin/settlements');
  }

  public getPublishedSettlements(): Observable<Settlement[]> {
    return this.createGetRequest<Settlement[]>('/admin/settlements/published');
  }

  public getAllSettlements(): Observable<Settlement[]> {
    return this.createGetRequest<Settlement[]>('/admin/settlements/all');
  }

  public publishSettlement(settlement: Settlement, comment: string): Observable<Settlement> {
    return this.createPutRequest<Settlement>('/admin/settlements/' + settlement.id + '/publish', {comment: comment});
  }

  public confirmSettlementPendingRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/confirm-settlement-pending', {comment: comment});
  }

  public rejectSettlementPendingRDC(rdc: RDC, comment: string, returnCode: ReturnCode): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/reject-settlement-pending', {
      comment: comment,
      returnCode: returnCode
    });
  }

  public returnRejectedToReadyRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/return-rejected', {comment: comment});
  }

  public cancelSettlement(settlement: Settlement, comment: string): Observable<Settlement> {
    return this.createPutRequest<Settlement>('/admin/settlements/' + settlement.id + '/cancel', {comment: comment});
  }

  public approveRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/approve', {comment: comment});
  }

  public dataReviewApproveRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/approve-data-review', {comment: comment});
  }

  public setMoreInfoRequiredRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/more-info-required', {comment: comment});
  }

  public rejectRDC(rdc: RDC, comment: string, returnCode: ReturnCode): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/reject', {comment: comment, returnCode: returnCode});
  }

  public addCommentToRDC(rdc: RDC, formData: FormData): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/comment', formData);
  }

  public backToPendingRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/back-to-pending', {comment: comment});
  }

  public backToPreviousStateRDC(rdc: RDC, comment: string): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/rdcs/' + rdc.id + '/return-to-previous-state', {comment: comment});
  }

  public createBatch(rdcs: RDC[]): Observable<RDC[]> {
    return this.createPostRequest('/admin/rdcs/x9', rdcs);
  }

  public getX9(settlement: Settlement): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/rdcs/x9/' + settlement.id);
  }

  public getACH(settlement: Settlement): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/rdcs/ach/' + settlement.id);
  }

  public getOfac(rdc: RDC): Observable<OfacRDCResult> {
    return this.createGetRequest<OfacRDCResult>('/admin/ofac/rdc/' + rdc.id).pipe(
      map(result => {
        const ofacResultClass = new OfacRDCResult();
        const object: OfacRDCResult = Object.assign(ofacResultClass, result);
        object.byPayorName.forEach(it => it.recordInformation = rdc.payor.name);
        object.byPayeeName.forEach(it => it.recordInformation = getPersonName(rdc.payeePerson));
        return object;
      })
    );
  }

  public getReconcileReports(): Observable<ReconcileReport[]> {
    return this.createGetRequest('/admin/reconcile-reports/all');
  }

  public getReconcileReportFilePdf(id: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/reconcile-reports/download?id=' + id);
  }

  public getReturnCodes(): Observable<ReturnCode[]> {
    return this.createGetRequest('/admin/return-codes/all');
  }

  public getRdcsForX9(x9_id): Observable<RDC[]> {
    return this.createGetRequest('/admin/rdcs/for-settlement/' + x9_id);
  }

  public getActivityStatusesRDCs(filter: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/activity-statuses', filter);
  }

  public getActivityStatusesRDCsCount(filter: RdcFilter): Observable<number> {
    return this.createGetRequestWithParams<number>('/admin/rdcs/status/activity-statuses/count', filter);
  }

  public getComplianceStatusesRDCs(filter: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/compliance-statuses', filter);
  }

  public getComplianceStatusesRDCsCount(filter: RdcFilter): Observable<number> {
    return this.createGetRequestWithParams<number>('/admin/rdcs/status/compliance-statuses/count', filter);
  }

  public getSettlementById(x9_id): Observable<Settlement> {
    return this.createGetRequest('/admin/settlements/' + x9_id);
  }

  public getCreditScheduleConfigForFi(fi_id: number): Observable<CreditScheduleConfigurationEntry[]> {
    return this.createGetRequest('/admin/credit-schedule-configuration/' + fi_id);
  }

  public addCreditScheduleConfigEntryForFi(
    fi_id: number, creditScheduleConfigurationEntry: CreditScheduleConfigurationEntry): Observable<CreditScheduleConfigurationEntry> {
    return this.createPostRequest<CreditScheduleConfigurationEntry>(
      '/admin/credit-schedule-configuration/' + fi_id, creditScheduleConfigurationEntry);
  }

  public deleteCreditScheduleConfigEntryForFi(
    creditScheduleConfigurationEntry: CreditScheduleConfigurationEntry): Observable<CreditScheduleConfigurationEntry> {
    return this.createDeleteRequest<CreditScheduleConfigurationEntry>(
      '/admin/credit-schedule-configuration/' + creditScheduleConfigurationEntry.id);
  }

  public updateCreditScheduleConfigEntryForFi(
    creditScheduleConfigurationEntry: CreditScheduleConfigurationEntry): Observable<CreditScheduleConfigurationEntry> {
    return this.createPutRequest<CreditScheduleConfigurationEntry>(
      '/admin/credit-schedule-configuration/', creditScheduleConfigurationEntry);
  }

  public getRdcsSettlementPendingFiltered(filters: RdcFilter) {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/settlement-pending', filters);
  }

  public getRdcsSettlementReadyFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/settlement-ready', filters);
  }

  public getConfirmedRDCsFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/confirmed', filters);
  }

  public getSettlementRejectedRDCsFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/settlement-rejected', filters);
  }

  public getPendingRDCsFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/pending', filters);
  }

  public getMoreInfoRequiredRDCsFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/more-info-required', filters);
  }

  public getApprovedRDCsFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/approved', filters);
  }

  public getRejectedRDCsFiltered(filters: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams<RDC[]>('/admin/rdcs/status/rejected', filters);
  }

  public getOfacCheckFoAllrRdcs(): Observable<{ [key: number]: number }> {
    return this.createGetRequest<{}>('/admin/ofac/check-rdc');
  }

  public getTransactionsFiltered(filter: TransactionFilter): Observable<Transaction[]> {
    return this.createGetRequestWithParams<Transaction[]>('/admin/transactions', filter);
  }

  public getMasterPersonsFiltered(filter: AdminKycProfilesFilter): Observable<Person[]> {
    return this.createGetRequestWithParams('/admin/persons', filter);
  }

  public getMasterPersonsFilteredCount(filter: AdminKycProfilesFilter): Observable<number> {
    return this.createGetRequestWithParams('/admin/persons/count', filter);
  }

  public getSelfRegisteredUsers(): Observable<SelfRegisteredUser[]> {
    return this.createGetRequest<SelfRegisteredUser[]>('/admin/self-registered-users');
  }

  public getSelfRegisteredUserById(id: number): Observable<SelfRegisteredUser> {
    return this.createGetRequest<SelfRegisteredUser>('/admin/self-registered-users/' + id);
  }

  public getTemplates(): Observable<Template[]> {
    return this.createGetRequest('/admin/templates');
  }

  public getTemplateById(id: number): Observable<Template> {
    return this.createGetRequest('/admin/templates/' + id);
  }

  public addTemplate(template: Template): Observable<void> {
    return this.createPostRequest('/admin/templates', template);
  }

  public editTemplate(templateToEdit: Template, templateEdited: Template): Observable<void> {
    return this.createPutRequest('/admin/templates/' + templateToEdit.id, templateEdited);
  }

  public deleteTemplate(template: Template): Observable<void> {
    return this.createDeleteRequest('/admin/templates/' + template.id);
  }

  public addQuestionForTemplate(template: Template, question: QuestionDefinition): Observable<void> {
    return this.createPostRequest('/admin/templates/' + template.id, question);
  }

  public editQuestionForTemplate(
    template: Template, questionToEdit: QuestionDefinition, questionEdited: QuestionDefinition): Observable<void> {
    return this.createPutRequest('/admin/templates/' + template.id + '/' + questionToEdit.id, questionEdited);
  }

  public deleteQuestionForTemplate(template: Template, question: QuestionDefinition): Observable<void> {
    return this.createDeleteRequest('/admin/templates/' + template.id + '/' + question.id);
  }

  public getWireTransferBankCustomers(): Observable<WireTransferBank[]> {
    return this.createGetRequest('/admin/wire-transfer-form/customers/banks');
  }

  public getWireTransferBankByQuery(searchString: string, fi_id: number): Observable<WireTransferBank[]> {
    return this.createGetRequest<WireTransferBank[]>(
      `/admin/wire-transfer-form/customers/banks/by-query?searchString=${searchString}&fi=${fi_id}`);
  }

  public getWireTransferBankCustomersFiltered(filter: AdminBankProfilesFilter): Observable<WireTransferBank[]> {
    return this.createGetRequestWithParams('/admin/wire-transfer-form/customers/banks', filter);
  }

  public getWireTransferBankById(id: number): Observable<WireTransferBank> {
    return this.createGetRequest<WireTransferBank>('/admin/wire-transfer-form/customers/banks/' + id);
  }

  public editWireTransferBank(wireTransferBank: WireTransferBank): Observable<WireTransferBank> {
    return this.createPutRequest<WireTransferBank>('/admin/wire-transfer-form/customers/banks/' + wireTransferBank.id, wireTransferBank);
  }

  public getProfileFromWireTransferBankId(id: number): Observable<KycProfile> {
    return this.createGetRequest<KycProfile>(`/admin/wire-transfer-form/customers/banks/${id}/profile`);
  }

  public confirmWireTransferBank(bank: WireTransferBank, object: { checked: boolean }) {
    return this.createPutRequest('/admin/wire-transfer-form/customers/banks/confirm/' + bank.id, object);
  }

  public getPersonsAllowedForWire(searchString: string, fi_id: number): Observable<Person[]> {
    return this.createGetRequest<Person[]>(`/admin/persons/allowed-for-wire/by-query?searchString=${searchString}&fi=${fi_id}`);
  }

  public getCustomerDocuments(profileId: number): Observable<CustomerDocument[]> {
    return this.createGetRequest(`/admin/profile/documents/${profileId}`);
  }

  public getAdminDocuments(profileId: number): Observable<CustomerDocument[]> {
    return this.createGetRequest(`/admin/profile/admin-documents/${profileId}`);
  }

  public postCustomerDocuments(profileId: number, formData: FormData): Observable<CustomerDocument[]> {
    return this.createPostRequest(`/admin/profile/documents/${profileId}`, formData);
  }

  public postAdminDocuments(profileId: number, formData: FormData): Observable<CustomerDocument[]> {
    return this.createPostRequest(`/admin/profile/admin-documents/${profileId}`, formData);
  }

  public getCustomerDocument(profileId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/admin/profile/documents/${profileId}/${documentId}`);
  }

  public getAdminDocument(profileId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/admin/profile/admin-documents/${profileId}/${documentId}`);
  }

  public deleteCustomerDocument(profileId: number, documentId: number): Observable<CustomerDocument> {
    return this.createDeleteRequest(`/admin/profile/documents/${profileId}/${documentId}`);
  }

  public deleteAdminDocument(profileId: number, documentId: number): Observable<CustomerDocument> {
    return this.createDeleteRequest(`/admin/profile/admin-documents/${profileId}/${documentId}`);
  }

  public updateAnswerQuestionForProfile(kycProfileId: number, questionId: string, questionFormData: QuestionFormData): Observable<void> {
    const formData = new FormData();
    formData.append('blocksJson', JSON.stringify(questionFormData.blocks));
    questionFormData.files.forEach(it => {
      formData.append('files', it);
    });
    return this.createPutRequest<void>(`/admin/questions/update-answer/${kycProfileId}/${questionId}`, formData);
  }

  public getAssignedTemplatesForProfile(profile: KycProfile): Observable<AssignedTemplate[]> {
    return this.createGetRequest('/admin/questions/new/' + profile.id);
  }

  public getPendingQuestionsForProfile(profile: KycProfile): Observable<QuestionDefinition[]> {
    return this.createGetRequest('/admin/questions/pending/' + profile.id);
  }

  public answerQuestionForProfileWithProgress(
    profileId: number, questionId: string, questionFormData: QuestionFormData): Observable<HttpEvent<void>> {
    const formData = new FormData();
    formData.append('blocksJson', JSON.stringify(questionFormData.blocks));
    questionFormData.files.forEach(it => {
      formData.append('files', it);
    });
    return this.createPostRequestWithProgress<void>(`/admin/questions/${profileId}/answer/${questionId}`, formData);
  }

  public getAnsweredQuestionsForProfile(profile: KycProfile): Observable<AnsweredQuestion[]> {
    return this.createGetRequest('/admin/questions/answered/' + profile.id);
  }

  public editQuestionForProfile(
    profile: KycProfile, questionToEdit: QuestionDefinition, questionEdited: QuestionDefinition): Observable<void> {
    return this.createPutRequest('/admin/questions/' + profile.id + '/' + questionToEdit.id, questionEdited);
  }

  public revokeTemplateFromProfile(profile: KycProfile, template: AssignedTemplate): Observable<void> {
    return this.createDeleteRequest('/admin/questions/' + profile.id + '/' + template['_id']);
  }

  public publishTemplatesForProfile(profile: KycProfile, questions: AssignedTemplate[]): Observable<void> {
    return this.createPutRequest('/admin/questions/publish/multiple/' + profile.id, questions);
  }

  public answerConfirmationChangeForProfile(event: {
    question: AnsweredQuestion,
    confirmed: boolean
  }, profile: Profile): Observable<void> {
    return this.createPutRequest('/admin/questions/answer/confirmation/' + profile.id, event);
  }

  public assignTemplatesForProfile(profile: KycProfile, selectedTemplates: Template[]): Observable<void> {
    return this.createPostRequest('/admin/questions/from-template/' + profile.id, selectedTemplates);
  }

  public getProfileAnswerFile(filename: string): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/questions/download/' + filename);
  }

  public putOnBoard(profile: KycProfile, object: { checked: boolean }) {
    return this.createPutRequest('/admin/profile/on-board/' + profile.id, object);
  }

  public saveComment(profile: KycProfile, object: { comment: string }) {
    return this.createPutRequest('/admin/profile/comment-box/' + profile.id, object);
  }

  public getProfileDetailsReport(profile: KycProfile): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/profile/report/' + profile.id);
  }

  getOfacForProfile(profile: KycProfile): Observable<OfacProfileResult> {
    return this.createGetRequest('/admin/ofac/profile/' + profile.id);
  }

  recheckOfacForProfile(profile: KycProfile): Observable<void> {
    return this.createGetRequest('/admin/ofac/re-check/profile/' + profile.id);
  }

  recheckOfacForName(name: string): Observable<void> {
    return this.createGetRequest('/admin/ofac/re-check/name/' + name);
  }

  recheckOfacForAddress(address: { address: string, country: string }): Observable<void> {
    return this.createGetRequestWithParams('/admin/ofac/re-check/address/', address);
  }

  recheckIdentityApiForRDC(rdc: RDC): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/ofac/re-check/rdc/' + rdc.id, {});
  }

  recheckIdentityApiForWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest<WireTransferForm>('/admin/ofac/re-check/wire-transfer-form/' + wireTransferForm.id, {});
  }

  getDistinctOfacCheckNames(filter: OfacLogsByNameFilter): Observable<OfacLogByNameEntry[]> {
    return this.createGetRequestWithParams('/admin/ofac-logs/name/distinct-checks', filter);
  }

  getDistinctOfacCheckNamesCount(filter: OfacLogsByNameFilter): Observable<number> {
    return this.createGetRequestWithParams('/admin/ofac-logs/name/distinct-checks/count', filter);
  }

  getOfacCheckForNameRecords(name: string): Observable<OfacCheckForName[]> {
    return this.createGetRequest('/admin/ofac-logs/name/all-checks/' + name);
  }

  getOfacRecordsForNameCheck(ofacCheck: OfacCheckForName): Observable<Ofac[]> {
    return this.createGetRequest('/admin/ofac-logs/name/all-records/' + ofacCheck.id);
  }

  getDistinctOfacCheckAddresses(filter: OfacLogsByAddressFilter): Observable<OfacLogByAddressEntry[]> {
    return this.createGetRequestWithParams('/admin/ofac-logs/address/distinct-checks', filter);
  }

  getOfacCheckForAddressRecords(queryParams: { address: string, country: string }): Observable<OfacCheckForAddress[]> {
    return this.createGetRequestWithParams('/admin/ofac-logs/address/all-checks/', queryParams);
  }

  getOfacRecordsForAddressCheck(ofacCheck: OfacCheckForAddress): Observable<Ofac[]> {
    return this.createGetRequest('/admin/ofac-logs/address/all-records/' + ofacCheck.id);
  }

  getKycProfileById(id: number): Observable<KycProfile> {
    return this.createGetRequest('/admin/profile/' + id);
  }

  getReportForOfacCheckForName(ofacCheckForName: OfacCheckForName): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/ofac-logs/name/report/' + ofacCheckForName.id);
  }

  getReportForOfacCheckForAddress(ofacCheckForAddress: OfacCheckForAddress): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/ofac-logs/address/report/' + ofacCheckForAddress.id);
  }

  getNameAppearancesForOfacCheck(name: string): Observable<any> {
    return this.createGetRequest('/admin/ofac-logs/name/appearance/' + name);
  }

  getAddressAppearancesForOfacCheck(queryParams: { address: string, country: string }): Observable<any> {
    return this.createGetRequestWithParams('/admin/ofac-logs/address/appearance', queryParams);
  }

  getOfacChecksForRdcs(): Observable<RDC[]> {
    return this.createGetRequest('/admin/ofac-checks/');
  }

  getOfacChecksForRdcsCount(): Observable<number> {
    return this.createGetRequest('/admin/ofac-checks/count');
  }

  getOfacChecksForRdcsFiltered(filter: RdcFilter): Observable<RDC[]> {
    return this.createGetRequestWithParams('/admin/ofac-checks/', filter);
  }

  getOfacChecksForRdcsFilteredCount(filter: RdcFilter): Observable<number> {
    return this.createGetRequestWithParams('/admin/ofac-checks/count', filter);
  }

  public downloadSearchActivityPdf(filters: TransactionFilter): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequestWithParams('/admin/transactions/search-activity-pdf', filters);
  }

  public fillMissingDataWithExcel(file: any): Observable<{ updatedRDCs: RDC[], populatedRDCs: RDC[] }> {
    const formData = new FormData();
    formData.append('excel', file);
    return this.createPostRequest<{
      updatedRDCs: RDC[],
      populatedRDCs: RDC[]
    }>('/admin/rdcs/populate-data-from-excel', formData);
  }

  public downloadExcelTemplate(): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/rdcs/download-template');
  }

  public getIncomingActivityWireTransferForms(filters: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/admin/wire-transfer-form/incoming-activity', filters);
  }

  public getIncomingComplianceWireTransferForms(filters: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/admin/wire-transfer-form/incoming-compliance', filters);
  }

  public getOutgoingActivityWireTransferForms(filters: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/admin/wire-transfer-form/outgoing-activity', filters);
  }

  public getOutgoingComplianceWireTransferForms(filters: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/admin/wire-transfer-form/outgoing-compliance', filters);
  }

  public getOutgoingProcessedWireTransferForms(): Observable<WireTransferForm[]> {
    return this.createGetRequest('/admin/wire-transfer-form/outgoing/status/processed');
  }

  public getWireBatches(): Observable<WiresBatches[]> {
    return this.createGetRequest('/admin/wire-transfer-form/outgoing/status/wire-batches');
  }

  public getWireBatchFromId(id: number): Observable<WiresBatches> {
    return this.createGetRequest('/admin/wire-transfer-form/outgoing/status/wire-batch/' + id);
  }

  public getExportsExistForWireBatch(id: number): Observable<{ exists: boolean }> {
    return this.createGetRequest('/admin/wire-transfer-form/outgoing/status/wire-batch-exports-existence/' + id);
  }

  public getExportFileForWireBatch(id: number, fileName: string): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/wire-transfer-form/outgoing/status/wire-batch-export/' + fileName);
  }

  public uploadBatchToRT(id: number, doNotSendToFTP: boolean): Observable<WiresBatches[]> {
    return this.createPutRequest('/admin/wire-transfer-form/outgoing/status/upload-batch-to-rt/' + id, {doNotSendToFTP});
  }

  public getWireTransferFormsOfBatch(id: number): Observable<WireTransferForm[]> {
    return this.createGetRequest('/admin/wire-transfer-form/outgoing/status/batches-ready/' + id);
  }

  public getOutgoingProcessedWireTransferFormsFiltered(filters: WireFormFilter): Observable<WireTransferForm[]> {
    return this.createGetRequestWithParams('/admin/wire-transfer-form/outgoing/status/processed', filters);
  }

  public getWireTransferForm(id: number): Observable<WireTransferForm> {
    return this.createGetRequest(`/admin/wire-transfer-form/${id}`);
  }

  public getWireTransferFormStatuses(): Observable<{
    outgoingStatuses: WireTransferFormStatusTypeConverted[],
    incomingStatuses: WireTransferFormStatusTypeConverted[]
  }> {
    return this.createGetRequest<{
      outgoingStatuses: WireTransferFormStatusTypeConverted[],
      incomingStatuses: WireTransferFormStatusTypeConverted[]
    }>('/admin/wire-transfer-form/status-types');
  }

  public exportWireTransferFormToPdf(id: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/admin/wire-transfer-form/${id}/pdf-export`);
  }

  public addCommentToWireTransferForm(wireTransferForm: WireTransferForm, formData: FormData): Observable<WireTransferForm> {
    return this.createPutRequest<WireTransferForm>('/admin/wire-transfer-form/' + wireTransferForm.id + '/comment', formData);
  }

  public setMoreInfoRequiredWireTransferForm(wireTransferForm: WireTransferForm, comment: string): Observable<WireTransferForm> {
    return this.createPutRequest<WireTransferForm>(
      '/admin/wire-transfer-form/' + wireTransferForm.id + '/more-info-required', {comment: comment});
  }

  public backToPreviousStateWireTransferForm(wireTransferForm: WireTransferForm, comment: string): Observable<WireTransferForm> {
    return this.createPutRequest<WireTransferForm>(
      '/admin/wire-transfer-form/' + wireTransferForm.id + '/return-to-previous-state', {comment: comment});
  }

  public assignIncomingWireTransferForm(wireTransferFormId: number, fiId: number, accountId: number): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/assign/${wireTransferFormId}/`, {
      fiId: fiId,
      accountId: accountId
    });
  }

  public getCurrencies(): Observable<{ value: string, currencyName: string }[]> {
    return of(currencies);
  }

  public createIncomingWireTransferFormsFromFedWire(file: any): Observable<WireTransferForm[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.createPostRequest<WireTransferForm[]>('/admin/wire-transfer-form/fedwire-import', formData);
  }

  public createIncomingWireTransferFormsFromSwift(file: any): Observable<WireTransferForm[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.createPostRequest<WireTransferForm[]>('/admin/wire-transfer-form/swift-import', formData);
  }

  public createIncomingWireTransferFormsFromRtJson(file: any): Observable<WireTransferForm[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.createPostRequest<WireTransferForm[]>('/admin/wire-transfer-form/rt-json-import', formData);
  }

  public downloadFedWireBatchFile(fileName: string): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/wire-transfer-form/fedwire-batch/' + fileName);
  }

  public downloadSwiftBatchFile(fileName: string): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/wire-transfer-form/swift-batch/' + fileName);
  }

  public downloadRtJsonFile(fileName: string): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest('/admin/wire-transfer-form/rt-json/' + fileName);
  }

  public updateWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/`, wireTransferForm);
  }

  public processWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/process/${wireTransferForm.id}`, {});
  }

  public uploadToRtFXWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/upload-to-rt/${wireTransferForm.id}`, {});
  }

  public createBatchReadyToUpload(wireTransferFormsArray: { items: Array<any> }, formValue: {
    doNotSendToFTP: boolean
  }): Observable<WiresBatches> {
    return this.createPutRequest(`/admin/wire-transfer-form/batches-ready/`, wireTransferFormsArray);
  }

  public setProcessedByRtWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/processed-by-rt/${wireTransferForm.id}`, {});
  }

  public processIncomingWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/process/incoming/${wireTransferForm.id}`, {});
  }

  public approveUltimateBeneficiary(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/${wireTransferForm.id}/ultimate-beneficiary/approve`, {});
  }

  public rejectUltimateBeneficiary(wireTransferForm: WireTransferForm, comment: string): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/${wireTransferForm.id}/ultimate-beneficiary/reject`, {comment});
  }

  public submitWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/submit/${wireTransferForm.id}`, {});
  }

  public rejectWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/wire-transfer-form/reject/${wireTransferForm.id}`, {});
  }

  public getFedWireBatchFromWireTransferFormIds(wireTransferFormIds: number[]): Observable<HttpResponse<Blob>> {
    return this.createBlobPostRequest(`/admin/wire-transfer-form/fedwire`, {wireTransferFormIds});
  }

  public getSwiftBatchFromWireTransferFormIds(wireTransferFormIds: number[]): Observable<HttpResponse<Blob>> {
    return this.createBlobPostRequest(`/admin/wire-transfer-form/swift`, {wireTransferFormIds});
  }

  public getMCBBatchFromWireTransferFormIds(wireTransferFormIds: number[]): Observable<HttpResponse<Blob>> {
    return this.createBlobPostRequest(`/admin/wire-transfer-form/mcb`, {wireTransferFormIds});
  }

  public exportWireTransferFormsToExcel(wireTransferFormIds: number[]): Observable<HttpResponse<Blob>> {
    return this.createBlobPostRequest('/admin/wire-transfer-form/export-excel', {wireTransferFormIds});
  }

  public exportWireTransferFormsToNextBank(wireTransferFormIds: number[]): Observable<HttpResponse<Blob>> {
    return this.createBlobPostRequest('/admin/wire-transfer-form/export-next-bank', {wireTransferFormIds});
  }

  public getRdcActivityDocument(activityId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/admin/rdcs/activities/${activityId}/document/${documentId}/download`);
  }

  public downloadWireTransferFormActivityDocument(activityId: number, documentId: number): Observable<HttpResponse<Blob>> {
    return this.createBlobGetRequest(`/admin/wire-transfer-form/activity/${activityId}/documents/${documentId}/download`);
  }

  public getWireTransferFormActivities(wireTransferId: number): Observable<WireTransferFormActivity[]> {
    return this.createGetRequest<WireTransferFormActivity[]>(`/admin/wire-transfer-form/${wireTransferId}/activity`);
  }

  public getAllNonWorkingDays(): Observable<NonWorkingDay[]> {
    return this.createGetRequest<NonWorkingDay[]>(`/admin/settings/non-working-days`);
  }

  public updateNonWorkingDay(nonWorkingDay: NonWorkingDay): Observable<NonWorkingDay> {
    return this.createPutRequest<NonWorkingDay>(`/admin/settings/non-working-days/${nonWorkingDay.id}`, nonWorkingDay);
  }

  public deleteNonWorkingDay(id: number): Observable<void> {
    return this.createDeleteRequest(`/admin/settings/non-working-days/${id}`);
  }

  public createNonWorkingDay(nonWorkingDay: NonWorkingDay): Observable<NonWorkingDay> {
    return this.createPostRequest<NonWorkingDay>(`/admin/settings/non-working-days`, nonWorkingDay);
  }

  public getBankDefaultQuestionnaires(fi: FinancialInstitution): Observable<DefaultQuestionnaire[]> {
    return this.createGetRequest<DefaultQuestionnaire[]>(`/admin/banks/${fi.id}/default-questionnaires`);
  }

  public createBankDefaultQuestionnaire(
    fi: FinancialInstitution, defaultQuestionnaire: DefaultQuestionnaire): Observable<DefaultQuestionnaire[]> {
    return this.createPostRequest<DefaultQuestionnaire[]>(`/admin/banks/${fi.id}/default-questionnaires`, defaultQuestionnaire);
  }

  public updateBankDefaultQuestionnaire(
    fi: FinancialInstitution, defaultQuestionnaire: DefaultQuestionnaire): Observable<DefaultQuestionnaire[]> {
    return this.createPutRequest<DefaultQuestionnaire[]>(
      `/admin/banks/${fi.id}/default-questionnaires/${defaultQuestionnaire.id}`, defaultQuestionnaire);
  }

  public deleteBankDefaultQuestionnaire(fi: FinancialInstitution, defaultQuestionnaire: DefaultQuestionnaire) {
    return this.createDeleteRequest(`/admin/banks/${fi.id}/default-questionnaires/${defaultQuestionnaire.id}`);
  }

  /**
   * @deprecated
   */
  public updateOriginatorPersonInRtJson(wireTranferForm: WireTransferForm, personObject: {
    name: string,
    address: string,
    ddaNumber: string
  }) {
    return this.createPutRequest(`/admin/wire-transfer-form/incoming/originator-person/${wireTranferForm.id}`, personObject);
  }

  /**
   * @deprecated
   */
  public updateBeneficiaryPersonInRtJson(wireTranferForm: WireTransferForm, personObject: {
    name: string,
    address: string,
    ddaNumber: string
  }) {
    return this.createPutRequest(`/admin/wire-transfer-form/incoming/beneficiary-person/${wireTranferForm.id}`, personObject);
  }

  public getThreshold(): Observable<number> {
    return this.createGetRequest<any>(`/admin/settings/threshold`);
  }

  public updateThreshold(amount: number): Observable<number> {
    return this.createPostRequest<any>(`/admin/settings/threshold`, {amount});
  }

  public mergeProfiles(persons: Person[], master: Person): Observable<void> {
    return this.createPostRequest('/admin/profile/merge-into/' + master.kyc_profile_id, {profileIds: persons.map(it => it.kyc_profile_id)});
  }

  public unmergePerson(person: Person): Observable<void> {
    return this.createPostRequest('/admin/profile/unmerge-person/' + person.id);
  }

  public getUpstreamBanks(): Observable<UpstreamBank[]> {
    return this.createGetRequest<UpstreamBank[]>('/admin/upstream-banks');
  }

  public getUpstreamBank(id: string): Observable<UpstreamBank> {
    return this.createGetRequest<UpstreamBank>('/admin/upstream-banks/' + id);
  }

  public addUpstreamBank(upb: UpstreamBank): Observable<UpstreamBank> {
    return this.createPostRequest<UpstreamBank>('/admin/upstream-banks', upb);
  }

  public updateUpstreamBank(upb: UpstreamBank): Observable<UpstreamBank> {
    return this.createPutRequest<UpstreamBank>('/admin/upstream-banks/' + upb.id, upb);
  }

  public repairUpstreamBankBraidWebhooks(upbId: number): Observable<UpstreamBank> {
    return this.createPutRequest<UpstreamBank>(`/admin/upstream-banks/${upbId}/braid-webhooks/repair`, {});
  }

  public deleteUpstreamBank(upb: UpstreamBank): Observable<UpstreamBank> {
    return this.createDeleteRequest<UpstreamBank>('/admin/upstream-banks/' + upb.id);
  }

  public getConfiguration(): Observable<BackendConfiguration> {
    return this.createGetRequest('/admin/configuration/');
  }

  recheckHawkAIForRDC(rdc: RDC): Observable<RDC> {
    return this.createPutRequest<RDC>('/admin/ofac/re-check/rdc/hawk/' + rdc.id, {});
  }

  recheckHawkAIForWireTransferForm(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest<WireTransferForm>('/admin/ofac/re-check/wire-transfer-form/hawk/' + wireTransferForm.id, {});
  }

  public acceptHawkAIResults(wireTransferForm: WireTransferForm): Observable<WireTransferForm> {
    return this.createPutRequest(`/admin/ofac/accept/wire-transfer-form/hawk/${wireTransferForm.id}`, {});
  }
}
